.navbar {
  // height: 80px;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.21);
  position: sticky;
  top: 0;
  z-index: 999;

  .wrapper {
    max-width: 1200px;
    width: 100%;
    padding: 15px 30px 10px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .language {
      display: none;
    }

    .left {
      display: none;
    }

    .center {
      font-size: 20px;
      letter-spacing: 2px;
      font-family: "Electrolize", sans-serif;

      .dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;

        .arrow {
          font-size: 30px;
        }
      }

      .menu {
        align-items: center;
        background-color: #fefdfc;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        left: 0;
        top: 55px;
        width: 200px;
        z-index: 3;
        padding: 20px;

        hr {
          border: 0;
          width: 90%;
          margin: 10px;
          height: 1px;
          background: #333;
          background-image: linear-gradient(to right, #ccc, #333, #ccc);
        }

        .item {
          padding: 10px;

          .menulink {
            text-decoration: none;
            color: #555555;
          }
        }
      }

      .overlay {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100vh;
        position: absolute;
        right: 0;
        top: 55px;
        width: 100vw;
      }

      .link {
        display: none;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 25px;

      .lang {
        height: 20px;
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 15px;
        color: grey;
        cursor: pointer;
        padding-left: 80px;

        .cartIcon {
          position: relative;

          span {
            font-size: 12px;
            border-radius: 50%;
            background-color: blue;
            color: white;
            width: 16px;
            height: 16px;
            position: absolute;
            right: -10px;
            top: -5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (min-width: 750px) {
  .navbar {
    .wrapper {
      padding: 10px 30px;

      .left {
        display: flex;
        align-items: center;
        gap: 25px;
      }

      .center {
        font-size: 30px;

        .link {
          display: block;
        }

        .dropdown {
          display: none;
        }
      }

      .language {
        display: block;
      }
    }
  }
}