.categoriesImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.products {
  padding: 30px 30px;
  display: flex;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
  flex-direction: column;

  .left {
    flex: 1;
    position: sticky;
    height: 100%;
    top: 80px;      
    display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

    .filterItem {
      margin-bottom: 20px;


      h2 {
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 15px;
      }

      .inputItem {
        margin-bottom: 10px;

        label {
          margin-left: 10px;
        }
      }
    }
  }

  .right {
    flex: 4;
    display: flex;
  }
}

@media (min-width: 750px) {
  .products {
    margin-top: 35px;
    flex-direction: row;
    padding: 30px 50px;
    margin-bottom: 200px;

    .left{
flex-direction: column;
    }
  }

  .categoriesImage {
    height: 300px;
  }
}
