.categories {
  display: flex;
  height: 35vh;
  gap: 10px;
  margin: 10px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    img:hover {
      transform: scale(1.5);
    }
  }

  .col-l {
    flex: 2;
  }

  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;

    img {
      transition: transform 0.5s ease;
      scale: 1.2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    button {
      width: 100%;
      position: absolute;
      height: 30px;
      font-weight: bold;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      border: none;
      background-color: rgba(255, 255, 255, 0.801);
      font-weight: bold;
    }
  }
}

@media (min-width: 750px) {
  .categories {
    height: 80vh;

    .row {
      button {
        min-width: 100px;
        width: fit-content;
        height: 40px;
      }
    }
  }
}
