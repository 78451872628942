.featuredProducts {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  margin-bottom: 50px;



  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    gap: 20px;

    h1 {
      flex: 2;
      text-transform: capitalize;
      font-size: 20px;
      letter-spacing: 1px;
    }

    p {
      text-align: center;
      padding: 0 40px 0;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 1s ease;
    gap: 30px;

    .gradient {
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 13%, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 1) 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
    }

    .content {
      margin-top: 2px;
      display: flex;
      justify-content: flex-start;
      gap: 50px;
      max-width: 1000px;
      transition: all 1s ease;
      margin-left: calc(100vh - 75px);
      padding: 0 20px;
    }

    .icon {
      width: 50px;
      height: 50px;
      color: black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #ff0;
      z-index: 3;
      position: absolute;
    }

    .left {
      left: 20px;
    }

    .right {
      right: 20px;
    }
  }
}

@media (min-width: 750px) {
  .featuredProducts {
    .top {
      flex-direction: row;
      gap: 0;

      h1 {
        font-size: 25px;
        margin-left: 40px;
      }

      p {
        display: block;
        flex: 3;
        color: gray;
      }
    }

    .bottom {
      .content {
        margin-left: auto;
      }

      .icon {
        position: inherit;
      }
    }
  }
}