.contact {
  background-color: yellow;
  padding: 15px;
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  margin-bottom: 50px;

  .wrapper {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  input {
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
  }

  button {
    padding: 10px;
    background-color: rgb(231, 231, 231);
    border: none;
    cursor: pointer;
  }

  .icons {
    display: none;
  }
}

@media (min-width: 750px) {
  .contact {
    .wrapper {
      gap: 0;
    }

    .icons{
      display: block;
        cursor: pointer;
        display: flex;
        gap: 10px;
    }
  }
}
