.app {
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .link {
    color: inherit;
    text-decoration: none;
  }
}
