.cart {
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 5;
  background-color: white;
  padding: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 90%;
  max-width: 400px;

  .empty {
    align-self: center;
    color: gray;
  }

  h1 {
    margin-bottom: 30px;
    color: gray;
    font-weight: bold;
    font-size: 24px;
  }

  .item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 30px;

    img {
      width: 80px;
      //   max-height: 80px;
      object-fit: cover;
    }

    .details {
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        color: gray;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          color: blue;
        }

        .remove {
          color: red;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .checkout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 200px;
      padding: 10px;
      background-color: yellow;
      color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border: none;
      font-weight: bold;
    }

    .reset {
      color: red;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
