.list {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  .notFound {
    font-size: 20px;
    font-weight: bold;
  }
}

@media (min-width: 750px) {
  .list {
    gap: 50px;
  }
}
