.footer {
  max-width: 1200px;
  margin: auto;
  padding: 0 30px 20px 30px;

  span {
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    .item {
      // flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;
    }

    .h1 {
      font-size: 18px;
      font-weight: 500px;
      color: gray;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-top: 50px;
    gap: 10px;

    .left {
      display: flex;
      align-items: center;

      .logo {
        color: gray;
        font-weight: bold;
        font-size: 24px;
      }

      .copyrights {
        margin-left: 20px;
        font-size: 12px;
        color: gray;
      }
    }

    .right {
      img {
        height: 50px;
      }
    }
  }
}

@media (min-width: 750px) {
  .footer {
    padding: 0 40px 20px 40px;

    .top {
      flex-wrap: nowrap;
      gap: 100px;
    }

    .bottom {
      flex-direction: row;
    }
  }
}