.product {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;

  .left {
    display: flex;
    flex: 1;
    gap: 20px;

    .images {
      flex: 1;

      img {
        object-fit: cover;
        width: 100%;
        height: 100px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }

    .mainImage {
      flex: 5;

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .price {
      font-size: 20px;
      color: blue;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      text-align: justify;
    }

    .quantity {
      display: flex;
      align-items: center;
      gap: 20px;
      font-weight: bold;

      button {
        width: 30px;
        height: 30px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .add {
      width: 200px;
      padding: 10px;
      height: 45px;
      background-color: yellow;
      color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border: none;
      font-weight: bold;
    }

    .link {
      display: flex;
      gap: 20px;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        color: gray;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: gray;
      font-size: 14px;
      margin-top: 30px;

      hr {
        width: 200px;
        border: 1px solid rgb(71, 71, 71);
      }
    }

    hr {
      border: 1px solid rgb(71, 71, 71);
    }
  }
}

@media (min-width: 750px) {
  .product {
    flex-direction: row;
    padding: 20px 50px;
    gap: 50px;
    margin-bottom: 200px;

    .images {
      img {
        height: 150px;
      }
    }
    .left {
      .mainImage {
        img {
          height: 600px;
        }
      }
    }
  }
}
