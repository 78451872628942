.card {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .image {
    width: 100%;
    height: 220px;
    overflow: hidden;
    position: relative;

    &:hover {
      .secondImage {
        z-index: 2;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      position: absolute;
    }

    .mainImage {
      z-index: 1;
    }

    span {
      position: absolute;
      top: 7px;
      left: 7px;
      background-color: yellow;
      color: black;
      padding: 3px 5px;
      z-index: 3;
      font-weight: bold;
      font-size: 12px;
    }
  }

  h2 {
    font-size: 16px;
  }

  .prices {
    display: flex;
    gap: 20px;

    h3 {
      font-size: 18px;

      .crossed {
        color: gray;
        text-decoration: line-through;
      }
    }
  }
}

@media (min-width: 750px) {
  .card {
    width: 250px;

    .image {
      height: 400px;
    }
  }
}